import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, AccordionTitle, AccordionContent } from '@hpstellar/core';

import { SearchContext } from './search-context';
import AccessibleElement from '../../ui/components/accessible-element';
import Icon from '../../ui/components/icon';
import Tooltip from '../../shared/components/tooltip';
import { Helpers } from '../../core/src/helpers';

import '../css/search-filter.less';

const getOptionsWithCanonical = (field, options, getFilterUrl = v => v) => {
    return options.map(option => {
        const { alias, value } = option;
        const url = getFilterUrl({ [`facets.${field}`]: [alias || value?.text] }, {});
        return {
            ...option,
            canonical: url,
        };
    });
};

/**
Context provider for search facet filte selection
@examples
```jsx
<SearchFilter
    displayName={displayName}
    options={facetValues}
    field={useAlias ? alias : key}
    metricsId={metricsId}
    CustomFilterOption={(props) => (
        <CustomFilterOptions useAlias={useAlias} {...props} />
    )}
    onFilterClick={closeModal}
    defaultExpanded={defaultExpanded}
/>
```
@component SearchFilter
@import SearchFilter
@example ../docs/SearchFilter.md
@returns {ReactElement} The rendered component
*/
export default class SearchFilter extends React.Component {
    static defaultProps = {
        defaultExpanded: false,
    };

    static propTypes = {
        defaultExpanded: PropTypes.bool,
        /**
         * Option custom component for rendinger filter options
         */
        CustomFilterOption: PropTypes.func,
        /**
         * Filter options to display
         */
        options: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.state = {
            expand: props.defaultExpanded,
        };
    }

    toggleMenu = () => {
        this.setState({
            expand: !this.state.expand,
        });
    };

    render() {
        let {
            displayName,
            field,
            operator,
            id,
            size,
            options,
            CustomFilterOption,
            metricsId,
            description,
            onFilterClick,
        } = this.props;
        //TODO: implement aggregations to field mapping,
        //currently only works when filter options passed in
        return (
            <SearchContext.Consumer>
                {({ updateQuery, aggregations, customContext, getFilterUrl }) => {
                    const { isV2 } = customContext || {};
                    if (isV2) {
                        return (
                            <Accordion className="accordion" defaultExpanded={this.state.expand} size="small">
                                <AccordionTitle
                                    tooltipPosition={'right'}
                                    tooltip={
                                        description && {
                                            description: (
                                                <span dangerouslySetInnerHTML={Helpers.createMarkup(description)} />
                                            ),
                                        }
                                    }
                                    title={displayName}
                                />
                                <AccordionContent className="accordionContent">
                                    {CustomFilterOption && (
                                        <CustomFilterOption
                                            options={getOptionsWithCanonical(field, options, getFilterUrl)}
                                            filterName={displayName}
                                            field={field}
                                            onClick={updateQuery}
                                            onFilterClick={onFilterClick}
                                            metricsId={metricsId}
                                        />
                                    )}
                                </AccordionContent>
                            </Accordion>
                        );
                    }

                    return (
                        <div className={`filter-group${this.state.expand ? ' expanded' : ' closed'}`}>
                            <AccessibleElement
                                element="h3"
                                className="filter-header"
                                ariaLabel={displayName}
                                onClick={this.toggleMenu}
                            >
                                {description && (
                                    <Tooltip ariaLabel={description} displayOn="click" linkId={`filter:${displayName}`}>
                                        {description}
                                    </Tooltip>
                                )}
                                {displayName}
                                <span className="arrow"></span>
                                <Icon name={this.state.expand ? 'minus_m' : 'plus'} size={20} />
                            </AccessibleElement>
                            <ul className="facet-values">
                                {CustomFilterOption && (
                                    <CustomFilterOption
                                        options={getOptionsWithCanonical(field, options, getFilterUrl)}
                                        filterName={displayName}
                                        field={field}
                                        onClick={updateQuery}
                                        onFilterClick={onFilterClick}
                                        metricsId={metricsId}
                                    />
                                )}
                            </ul>
                        </div>
                    );
                }}
            </SearchContext.Consumer>
        );
    }
}
