import React from 'react';

import { QuantityInput, useAccordionContext } from '@hpstellar/core';
import { Minus, ChevronRight } from '@hpstellar/icons';

import { formatMetricValue, PRICE_GTM_ID } from '../../metrics/metrics-helpers';
import { getInputProps } from '../util';

import './range-input.less';

export default function RangeInput({ min, max, setRangeMin, setRangeMax, submitRange, className, inputType }) {
    const values = useAccordionContext();
    const { expanded } = values || {};

    const preventFocusProp = {
        tabindex: -1,
    };

    return (
        <div className={`priceRange ${className ? className : ''}`}>
            {/* TODO: input validation */}
            <QuantityInput
                className="input"
                onChange={setRangeMin}
                symbol="$"
                symbolPosition="left"
                type={inputType}
                value={min}
                {...getInputProps(expanded, 'Min', 'Minimum price', preventFocusProp)}
            />
            <span className="divider">
                <Minus size="s" />
            </span>
            <QuantityInput
                className="input"
                onChange={setRangeMax}
                symbol="$"
                symbolPosition="left"
                type={inputType}
                value={max}
                {...getInputProps(expanded, 'Max', 'Maximum price', preventFocusProp)}
            />
            <button
                className="button"
                onClick={submitRange}
                type="button"
                data-gtm-id={PRICE_GTM_ID}
                data-gtm-value={formatMetricValue([min, max].join('-'), ['$'])}
                data-gtm-category="facet"
                data-gtm-checked
                aria-label="Submit"
                {...(!expanded ? preventFocusProp : {})}
            >
                <ChevronRight size="s" color="white" />
            </button>
        </div>
    );
}
