import React from 'react';

import { SearchContext } from './search-context';
import SearchClearFilter from './search-clear-filter';
import '../css/search-side-bar.less';

const DefaultHeader = ({ forceExpand = false, children }) => {
    return (
        <React.Fragment>
            <div className={`filters-heading filters-clear clearfix${forceExpand ? ' no-bottom-border' : ''}`}>
                <h5
                    tabIndex={0}
                    aria-label="Filters"
                    className={`${forceExpand ? ' shrink-filters-text float-none' : ''}`}
                >
                    Filters
                </h5>
                {children}
            </div>
        </React.Fragment>
    );
};

export default class SearchSideBar extends React.Component {
    render() {
        let { children, style, className } = this.props;
        return (
            <SearchContext.Consumer>
                {({ query, aggregations, customAggregations, searchInProgress }) => {
                    return (
                        <div
                            className={`search-side-bar${searchInProgress ? ' searching' : ''}${
                                className ? ' ' + className : ''
                            }`}
                        >
                            <div className="category-filters clearfix" style={style}>
                                {children && children({ query, aggregations, customAggregations, DefaultHeader })}
                            </div>
                        </div>
                    );
                }}
            </SearchContext.Consumer>
        );
    }
}
