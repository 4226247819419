import React from 'react';
import { Accordion, AccordionTitle, AccordionContent } from '@hpstellar/core';
import { Checkbox, Ratings } from '@hpstellar/core';

import { SearchContext } from './search-context';
import AccessibleElement from '../../ui/components/accessible-element';
import FacetCheckbox from './facet-checkbox';
import Icon from '../../ui/components/icon';
import Tooltip from '../../shared/components/tooltip';
import { Helpers } from '../../core/src/helpers';

const getOptionCanonical = (field, option, getFilterUrl = v => v) => {
    const { alias, value } = option;
    return getFilterUrl({ [`facets.${field}`]: [alias || value?.text] }, {});
};

export default class SearchRatingFilter extends React.Component {
    static defaultProps = {
        options: ['5', '4', '3', '2', '1', '0'],
    };

    constructor(props) {
        super(props);
        this.state = {
            expand: props.defaultExpanded,
        };
    }

    handleChange = (updateQuery, opt) => e => {
        const { field, useAlias } = this.props;
        let { selected, count, _id, alias } = opt;
        let queryValue = useAlias ? alias : _id;
        const action = selected ? 'remove' : 'replace';
        if (count === 0) {
            return;
        }
        setTimeout(() => {
            updateQuery(action, { [`${field}`]: queryValue });
        }, 0);
    };

    toggleMenu = () => {
        this.setState({
            expand: !this.state.expand,
        });
    };

    /**
     * Assumes ratings are a keyed range filter aggregation
     */
    mapAggregation = aggs => {
        const { options, field } = this.props;
        let fieldAgg = aggs[field] && (aggs[field][`${field}_filtered`] || aggs[field]);
        let { buckets = {} } = fieldAgg || {};
        return options.reduce((r, k) => {
            let bucketKey = k === '0' ? '*-0.0' : `${k}.0-*`;
            r[k] = (buckets[bucketKey] || {}).doc_count;
            return r;
        }, {});
    };

    render() {
        let {
            displayName,
            format,
            field,
            options,
            CustomFilterOption,
            onFilterClick,
            metricsId,
            description,
            isV2,
            className,
            aligned,
            gtmActions,
            useAlias,
        } = this.props;

        return (
            <SearchContext.Consumer>
                {({ updateQuery, query, aggregations, getFilterUrl }) => {
                    let bucketMap = this.mapAggregation(aggregations);
                    if (isV2) {
                        return (
                            <Accordion
                                className="accordion"
                                defaultExpanded={this.state.expand}
                                size="small"
                                onClick={this.toggleMenu}
                            >
                                <AccordionTitle
                                    tooltipPosition={'right'}
                                    tooltip={
                                        description && {
                                            description: (
                                                <span dangerouslySetInnerHTML={Helpers.createMarkup(description)} />
                                            ),
                                        }
                                    }
                                    title={displayName}
                                />
                                <AccordionContent className="accordionContent">
                                    {CustomFilterOption ? (
                                        <CustomFilterOption
                                            options={options}
                                            filterName={displayName}
                                            field={field}
                                            onClick={updateQuery}
                                            onFilterClick={onFilterClick}
                                            metricsId={metricsId}
                                        />
                                    ) : (
                                        options.map(opt => {
                                            let { displayName, selected, count, _id, type, value = {}, alias } = opt;
                                            const canonical = getOptionCanonical(field, opt, getFilterUrl);
                                            let { text } = value;
                                            let rating = text * 1;
                                            const disabled = count === 0;
                                            const checkBox = (
                                                <Checkbox
                                                    key={_id}
                                                    className={className}
                                                    checked={selected}
                                                    counter={count}
                                                    onChange={this.handleChange(updateQuery, opt)}
                                                    aligned={aligned}
                                                    gtmActions={gtmActions}
                                                    disabled={disabled || !this.state.expand} // if accordion is not expanded, set disabled=true so it can't be focused.
                                                >
                                                    <Ratings
                                                        className="filterRating"
                                                        score={rating}
                                                        showScore={false}
                                                    />
                                                </Checkbox>
                                            );
                                            return canonical ? (
                                                <a href={canonical} key={_id} onClick={e => e.preventDefault()}>
                                                    {checkBox}
                                                </a>
                                            ) : (
                                                checkBox
                                            );
                                        })
                                    )}
                                </AccordionContent>
                            </Accordion>
                        );
                    }
                    return (
                        <div className={`filter-group${this.state.expand ? ' expanded' : ' closed'}`} key="ratings">
                            <AccessibleElement
                                element="h3"
                                className="filter-header"
                                ariaLabel={displayName}
                                onClick={this.toggleMenu}
                            >
                                {description && (
                                    <Tooltip ariaLabel={description} displayOn="click">
                                        {description}
                                    </Tooltip>
                                )}
                                {displayName}
                                <span className="arrow"></span>
                                <Icon name={this.state.expand ? 'minus_m' : 'plus'} size={20} />
                            </AccessibleElement>
                            <ul className="facet-values">
                                {CustomFilterOption ? (
                                    <CustomFilterOption
                                        options={options}
                                        filterName={displayName}
                                        field={field}
                                        onClick={updateQuery}
                                        onFilterClick={onFilterClick}
                                        metricsId={metricsId}
                                    />
                                ) : (
                                    options.map(r => {
                                        const displayName = r !== '0' ? `${r} Stars & up` : 'None';
                                        const type = 'rating';
                                        const selected = query[field] && query[field].includes(r);
                                        return (
                                            <li className={`facet-value facet-${type}`} key={r}>
                                                <div
                                                    className={'facet-value-detail'}
                                                    onClick={this.handleChange(updateQuery, r)}
                                                >
                                                    <FacetCheckbox
                                                        type={type}
                                                        selected={selected}
                                                        displayName={displayName}
                                                        count={bucketMap[r]}
                                                        value={r}
                                                    />
                                                </div>
                                            </li>
                                        );
                                    })
                                )}
                            </ul>
                        </div>
                    );
                }}
            </SearchContext.Consumer>
        );
    }
}
