import React from 'react';
import { MetricsElement } from 'react-metrics';

import { withError } from '../shared/components/error-boundary';
import SearchSideBar from '../search-filter/components/search-side-bar';
import SearchFilter from '../search-filter/components/search-filter';
import SearchClearFilter from '../search-filter/components/search-clear-filter';
import SearchRangeFilter, { mapPriceRangeFacetValues } from '../search-filter/components/search-range-filter';
import SearchRatingFilter from '../search-filter/components/search-rating-filter';
import CategoriesMenu from './components/categories';
import { SelectedCategory, findCat } from './components/select-categories';
import CustomFilterOptions, { CustomRatingOptions } from './components/filters-option';

//temp location for this until LeftMenu refactor
class FilterMenu extends React.Component {
    static defaultProps = {
        defaultExpandedFilters: 6,
    };

    renderFilters = (
        facets,
        query,
        aggregations,
        metricName,
        closeModal,
        defaultExpandedFilters,
        useAlias,
        disableAutoFilters,
        align
    ) => {
        const { setVisibility, selectedFilters, customFilterClick, isFilterSelected } = this.props;

        const { min_price = { value: 0 }, max_price = { value: 1 } } = aggregations || {};
        const { value: minRange } = min_price || {};
        const { value: maxRange } = max_price || {};
        const showPriceRange = minRange && maxRange;

        //if being used by left menu activate
        //left menu if filter options are available
        if (facets && facets.length > 0 && setVisibility && !disableAutoFilters) {
            setVisibility(true);
        }

        return (
            facets instanceof Array &&
            facets.map((agg, i) => {
                const { displayName, facetValues, key, metricsId, alias, description } = agg;
                let hasSelection = (facetValues.filter(v => v.selected) || []).length > 0;
                let defaultExpanded = hasSelection || i < defaultExpandedFilters;

                if (key === 'price') {
                    return showPriceRange ? (
                        <MetricsElement key={`${key}-${i}`} element="span">
                            <SearchRangeFilter
                                description={description}
                                displayName={displayName || 'PRICE RANGE'}
                                minRange={minRange * 1}
                                maxRange={maxRange * 1}
                                field={'price'}
                                defaultExpanded={defaultExpanded}
                                isV2={this.props.isV2}
                                range={mapPriceRangeFacetValues(facetValues)}
                            />
                        </MetricsElement>
                    ) : null;
                }

                if (key === 'rating') {
                    return (
                        <SearchRatingFilter
                            key={`${key}-${i}`}
                            displayName={displayName}
                            minRange={minRange * 1}
                            maxRange={maxRange * 1}
                            field={useAlias ? alias : key}
                            defaultExpanded={defaultExpanded}
                            description={description}
                            options={facetValues}
                            onFilterClick={closeModal}
                            isV2={this.props.isV2}
                            useAlias={useAlias}
                            CustomFilterOption={
                                this.props.isV2
                                    ? null
                                    : props => <CustomRatingOptions useAlias={useAlias} {...props} align={align} />
                            }
                        />
                    );
                }

                return (
                    <SearchFilter
                        displayName={displayName}
                        options={facetValues}
                        key={`${key}-${i}`}
                        field={useAlias ? alias : key}
                        metricsId={metricsId}
                        description={description}
                        CustomFilterOption={props => (
                            <CustomFilterOptions
                                useAlias={useAlias}
                                {...props}
                                isFilterSelected={isFilterSelected}
                                selected={isFilterSelected ? isFilterSelected : props.selected}
                                isV2={this.props.isV2}
                                align={align}
                            />
                        )}
                        onFilterClick={customFilterClick || closeModal}
                        selectedFilters={selectedFilters}
                        defaultExpanded={defaultExpanded}
                    />
                );
            })
        );
    };

    render() {
        const {
            closeModal,
            defaultExpandedFilters,
            useAlias,
            disableAutoFilters,
            facetsModifier,
            className,
            isV2,
            align,
            onClearFilter,
            aggregationOverride,
            checkboxAligned,
        } = this.props;

        return (
            <SearchSideBar className={className}>
                {({ query, aggregations = {}, customAggregations = {}, DefaultHeader }) => {
                    //TODO: remove aggregationOverride once Blogs have been migrated to use the search provider
                    const { facets = [] } = aggregationOverride || customAggregations;
                    //hacky way to get metrics prefix for filter category
                    const [firstAggegrate = {}] = facets || [];
                    const { metricsId = '' } = firstAggegrate || {};
                    const [metricName] = metricsId ? metricsId.split(':') : [];
                    return (
                        <React.Fragment>
                            <CategoriesMenu useAlias={useAlias} isV2={isV2} aligned={checkboxAligned} />
                            {
                                <DefaultHeader>
                                    <MetricsElement
                                        element="span"
                                        data-metrics-event-name="searchFilter"
                                        data-metrics-event="e_facet"
                                        data-metrics-filter-clear-facet={true}
                                        data-metrics-filter-categories={`${metricName ? metricName + ':' : ''}clear`}
                                        data-metrics-filter-value={'all'}
                                    >
                                        <SearchClearFilter
                                            watchFields={/(facets.*|price|rating)/}
                                            onClick={onClearFilter || closeModal}
                                        />
                                    </MetricsElement>
                                </DefaultHeader>
                            }
                            {this.renderFilters(
                                facetsModifier ? facetsModifier(facets) : facets,
                                query,
                                aggregations,
                                metricName,
                                closeModal,
                                defaultExpandedFilters,
                                useAlias,
                                disableAutoFilters,
                                align
                            )}
                        </React.Fragment>
                    );
                }}
            </SearchSideBar>
        );
    }
}

export default withError(FilterMenu);

export { CategoriesMenu, SelectedCategory, findCat };
