import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@hpstellar/core';

const SWATCH_TYPES = {
    'color-hex': 1,
};

/**
A component for rendering a checkbox facet selection
@examples
```jsx
<FacetCheckbox
    type={'color-hex'}
    selected={true}
    displayName={'Colors'}
    count={10}
    value={'Red'}
/>
```
@component FacetCheckbox
@import FacetCheckbox
@example ../docs/FacetCheckbox.md
@returns {ReactElement} The rendered component
*/
export default class FacetCheckbox extends React.PureComponent {
    static defaultProps = {
        selected: false,
    };
    static propTypes = {
        /**
         * Boolean to indicate if it's a selected facet option
         */
        selected: PropTypes.bool,
        /**
         * Display name for facet selection
         */
        displayName: PropTypes.string,
        /**
         * Aggergate number of docs for this facet type
         */
        count: PropTypes.number,
        /**
         * Value off the facet
         */
        value: PropTypes.string,
        /**
         * Type used to change diplay of checkbox type facet
         */
        type: PropTypes.oneOf([undefined, 'color-hex', 'text']),
    };
    render() {
        const { selected, displayName, count, type, value, children, isV2, align, canonical } = this.props;
        const isSwatch = type in SWATCH_TYPES;
        const title = isSwatch ? displayName : null;
        let swatchStyle;
        if (type === 'color-hex') {
            swatchStyle = {
                backgroundColor: value,
            };
        }
        if (isV2) {
            const child = (
                <Checkbox
                    className="facetCheckbox"
                    checked={selected}
                    disabled={count === 0}
                    counter={count}
                    aligned={align}
                >
                    {children || displayName}
                </Checkbox>
            );
            return canonical ? (
                <a
                    onClick={e => {
                        e?.preventDefault();
                    }}
                    href={canonical}
                >
                    {child}
                </a>
            ) : (
                child
            );
        }

        return (
            <React.Fragment>
                <span title={title} style={swatchStyle} className={`chkbox${selected ? ' checked' : ''}`}></span>
                {/*by defaultd dont show name and count for swatch types*/}
                {!isSwatch && !children && <span className="facet-value-name">{displayName}</span>}
                {!!children && children}
                {!isSwatch && <span className="facet-value-count">({count})</span>}
            </React.Fragment>
        );
    }
}
