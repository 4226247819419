import React from 'react';
import debounce from 'lodash/debounce';

import { Accordion, AccordionTitle, AccordionContent, Checkbox } from '@hpstellar/core';

import { SearchContext } from './search-context';
import { Helpers } from '../../core/src/helpers';
import { withError } from '../../shared/components/error-boundary';
import Icon from '../../ui/components/icon';
import Tooltip from '../../shared/components/tooltip';
import RangeInput from '../../vwa/components/range-input';
import { getPriceFacetsGtmActions } from '../../metrics/metrics-helpers';

// import 'nouislider/distribute/nouislider.css';
import '../css/search-range-filter.less';

const getOptionCanonical = (field, option, getFilterUrl = v => v) => {
    const { alias, value } = option;
    return getFilterUrl({ [field]: [alias || value?.text] }, {});
};

export const mapPriceRangeFacetValues = facetValues => {
    const values = (facetValues || []).reduce((r, fv) => {
        if (fv.type == 'text') {
            const { displayName, value } = fv;
            const { text } = value || {};
            const [min, max] = (text || '').split(/\+|\-/);
            min &&
                max &&
                r.push({
                    value: displayName,
                    range: [min, max],
                });
        }
        return r;
    }, []);

    return values.length < 1 ? undefined : values;
};

const invalidRange = (aggregations, range) => {
    try {
        const { max_price, min_price } = aggregations;
        const { value: maxPrice } = max_price;
        const { value: minPrice } = min_price;
        const [minRange, maxRange] = range;
        return minRange * 1 > maxPrice || maxRange * 1 < minPrice;
    } catch (e) {
        return false;
    }
};

class SearchRangeFilter extends React.Component {
    static defaultProps = {
        format: {
            // 'to' the formatted value. Receives a number.
            to: function (value) {
                return Math.floor(value);
            },
            // 'from' the formatted value.
            // Receives a string, should return a number.
            from: function (value) {
                return Math.floor(value);
            },
        },
        currencySymbol: '$',
    };

    constructor(props) {
        super(props);
        this.state = {
            expand: props.defaultExpanded,
            sliding: false,
            min: 0,
            max: 10,
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    handleChange(updateQuery, customMin, customMax) {
        const { field } = this.props;
        return data => {
            let min = customMin || data[0];
            let max = customMax || data[1];
            setTimeout(() => {
                updateQuery('replace', { [`${field}`]: [min, max] });
                this.setState({
                    sliding: false,
                });
            }, 0);
        };
    }

    setMin = (updateQuery, max) => {
        const { field, currencySymbol } = this.props;
        return e => {
            const { target } = e;
            let { value } = target;
            value = updateQuery ? Helpers.convertPriceToNumber(value.replace(currencySymbol, '')) : value;
            let queryValue = [value, max];
            updateQuery ? updateQuery('replace', { [`${field}`]: queryValue }) : this.onSlide(queryValue);
        };
    };

    setMax = (updateQuery, min) => {
        const { field, currencySymbol } = this.props;
        return e => {
            const { target } = e;
            let { value } = target;
            value = updateQuery ? Helpers.convertPriceToNumber(value.replace(currencySymbol, '')) : value;
            let queryValue = [min, value];
            updateQuery ? updateQuery('replace', { [`${field}`]: queryValue }) : this.onSlide(queryValue);
        };
    };

    toggleMenu = () => {
        this.setState({
            expand: !this.state.expand,
        });
    };

    onSlide = value => {
        let [min, max] = value;
        this.setState({
            sliding: true,
            min,
            max,
        });
    };

    SLPRangeMap = [
        {
            value: 'Under $500',
            range: ['0', '500'],
        },
        {
            value: '$500 to $800',
            range: ['500', '800'],
        },
        {
            value: '$800 to $1200',
            range: ['800', '1200'],
        },
        {
            value: '$1200 to $1500',
            range: ['1200', '1500'],
        },
        {
            value: 'Over $1500',
            range: ['1500', '99999'],
        },
    ];

    render() {
        let {
            displayName,
            format,
            field,
            minRange,
            rangeOnly,
            maxRange,
            description,
            isV2,
            currencySymbol,
            className,
            customUpdateQuery,
            customQuery,
            range = this.SLPRangeMap,
        } = this.props;
        let { sliding, min: stateMin, max: stateMax } = this.state;

        return (
            <SearchContext.Consumer>
                {({ updateQuery, aggregations, query, searchInProgress, getFilterUrl }) => {
                    //if a field is provided use value from query
                    let [min = minRange, max = maxRange] = (customQuery && customQuery[field]) || query[field] || [];
                    //display the state min and max while search is being performed
                    let displayMin = sliding || searchInProgress ? stateMin : min;
                    let displayMax = sliding || searchInProgress ? stateMax : max;
                    let updateFunc = typeof customUpdateQuery === 'function' ? customUpdateQuery : updateQuery;

                    return (
                        <Accordion className="accordion" defaultExpanded={this.state.expand} size="small">
                            <AccordionTitle
                                tooltipPosition={'right'}
                                tooltip={
                                    description && {
                                        description: (
                                            <span dangerouslySetInnerHTML={Helpers.createMarkup(description)} />
                                        ),
                                    }
                                }
                                title={displayName}
                            />
                            <AccordionContent className="accordionContent">
                                <RangeInput
                                    className="slpRangeInput"
                                    min={displayMin}
                                    max={displayMax}
                                    setRangeMin={this.setMin(undefined, displayMax)}
                                    setRangeMax={this.setMax(undefined, displayMin)}
                                    submitRange={this.handleChange(updateFunc, displayMin, displayMax)}
                                />
                                {range.map((c, i) => {
                                    const selectRange =
                                        c?.range?.[0] === query?.price?.[0] && c?.range?.[1] === query?.price?.[1];
                                    const { value } = c || {};
                                    const gtmActions = getPriceFacetsGtmActions(null, value, !selectRange);
                                    const canonical = getOptionCanonical(
                                        field,
                                        { alias: c.range.join('+') },
                                        getFilterUrl,
                                    );
                                    const checkBox = (
                                        <Checkbox
                                            key={i}
                                            checked={selectRange}
                                            disabled={invalidRange(aggregations, c?.range)}
                                            onChange={() => {
                                                setTimeout(() => {
                                                    this.setState({ sliding: false });
                                                    updateQuery('replace', {
                                                        [`${this.props.field}`]: selectRange ? [] : c.range,
                                                    });
                                                }, 0);
                                            }}
                                            gtmActions={gtmActions}
                                        >
                                            {c.value}
                                        </Checkbox>
                                    );
                                    return canonical ? (
                                        <a
                                            href={decodeURIComponent(canonical)}
                                            key={i}
                                            onClick={e => e.preventDefault()}
                                        >
                                            {checkBox}
                                        </a>
                                    ) : (
                                        checkBox
                                    );
                                })}
                            </AccordionContent>
                        </Accordion>
                    );
                }}
            </SearchContext.Consumer>
        );
    }
}

export default withError(SearchRangeFilter);
