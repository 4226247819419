import React from 'react';
import PropTypes from "prop-types";
import { MetricsElement } from "react-metrics";

import Icon from '../../ui/components/icon';

import '../css/tooltip.less';

function ToolTipBubble({ linkId }) {
    const iconComponent = <Icon name='tooltip' className='tooltip-bubble' size={null} />;
    if (linkId) {
        return <MetricsElement>
            <span
                data-metrics-event-name="linkClick"
                data-metrics-event="e_linkClick"
                data-metrics-link-id={`tooltip:${linkId.toLowerCase()}`}
            >
                {iconComponent}
            </span>
        </MetricsElement>
    } else {
        return iconComponent;
    }
}

class Tooltip extends React.Component {
    static defaultProps = {
        label: "?",
        displayOn: 'mouseover'
    }

    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
            animating: false,
            offset: 0
        }
        this.animate = this.animate.bind(this);
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    animate(show) {
        clearTimeout(this.timeout);
        const offset = this.state.offset > 0 ? this.state.offset : this.getOffset();
        this.setState({ showTooltip: show, animating: true, offset });
        this.timeout = setTimeout(() => { this.setState({ animating: false }) }, 300);
    }

    getOffset() {
        if (this.popover) {
            return this.popover.offsetLeft + 300 - window.innerWidth;
        }
    }

    toggleTooltip(action) {
        const { displayOn } = this.props;
        return (e) => {
            const { type } = e;
            if (displayOn === 'mouseover' && !(type in { 'mouseover': 1, 'mouseleave': 1, 'keyup': 1 })) {
                return;
            } else if (displayOn === 'click' && !(type in { 'keyup': 1, 'click': 1 })) {
                return;
            }
            e.stopPropagation()
            let isTooltipElement = e.target.tagName === 'path' || e.target.className.indexOf && e.target.className.indexOf('tooltip') > -1;
            if (action === "close" && this.state.showTooltip) this.animate(false)
            else if (action === "open") {
                if (isTooltipElement) this.animate(true);
            }
            else if (!this.state.showTooltip && isTooltipElement) this.animate(true);
            else if (this.state.showTooltip) this.animate(false);
        }
    }

    handleKeyup = (e) => {
        let charCode = e.keyCode || e.which;
        if (charCode === 13) {
            if (!this.state.showTooltip) {
                this.toggleTooltip("open")(e)
            } else {
                this.toggleTooltip("close")(e)
            }
        }
    }

    closeToolTip = () => {
        this.setState({ showTooltip: false })
    }

    render() {
        const { children, label, displayOn, className, linkId } = this.props;
        const { showTooltip, animating, offset } = this.state;
        let isDefaultTooltip = label === '?';
        let popoverStyle = {};
        let offsetStyle = {};
        if (offset > 0) {
            if (!isDefaultTooltip) popoverStyle['right'] = 0;
            offsetStyle.left = offset;
        }

        const childrenWithProps = React.Children.map(children, child => {
            if (child.type && typeof child.type !== 'string') {
                return React.cloneElement(child, {
                    showTooltip,
                    closeToolTip: this.closeToolTip
                });
            } else {
                return child;
            }
        })

        return(
            <div aria-label={this.props.ariaLabel} 
                className={`tooltip${className ? ' ' + className : ''}${isDefaultTooltip ? ' default-tooltip' : ''}${showTooltip ? ' show' : ''}${animating ? ' animating' : ''}`} tabIndex={0} 
                onKeyUp={this.handleKeyup} 
                onMouseOver={this.toggleTooltip("open")} 
                onMouseLeave={this.toggleTooltip("close")}
                onClick={this.toggleTooltip()}
            >
                {typeof label === "function" ? label() : isDefaultTooltip ? <ToolTipBubble linkId={linkId} /> : label}
                {showTooltip && <div className={`tooltip-popover ${displayOn}`} ref={ref => this.popover = ref} style={popoverStyle}>
                    {(displayOn === 'click' || className === 'v2') && <div className='hp-icon close' tabIndex={0} onClick={this.closeToolTip}></div>}
                    {displayOn !== 'click' && <div className="popover-before" style={offsetStyle} />}
                    {childrenWithProps}
                    {displayOn !== 'click' && <div className="popover-after" style={offsetStyle} />}
                </div>}
            </div>
        )
    }


}

Tooltip.propTypes = {
    /** Label is what is displayed, if left blank it will show a circle with a "?" */
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func
    ]),
    /** Set the event that will tricket the tooltip flyout */
    displayOn: PropTypes.oneOf(["mouseover", "click"])
}

export default Tooltip;
