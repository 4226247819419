import React from 'react';
import { Accordion, Checkbox, AccordionTitle, AccordionContent } from '@hpstellar/core';

import AccessibleElement from '../../ui/components/accessible-element';
import SearchCustomContext from '../../search-filter/components/search-custom-context';
import { findCat } from './select-categories';
import Icon from '../../ui/components/icon';
import { Helpers } from '../../core/src/helpers';

export default class CategoriesMenu extends React.PureComponent {
    state = {
        menuExpanded: false,
    };

    toggleMenu = () => {
        this.setState({
            menuExpanded: !this.state.menuExpanded,
        });
    };

    changeCategory = (category, setQuery) => {
        const { useAlias } = this.props;
        return () => {
            setQuery({
                cat: [useAlias ? category._id : category.key],
            });
            this.toggleMenu();
        };
    };

    render() {
        let menuExpanded = this.state.menuExpanded;
        const { useAlias, isV2, description, gtmActions, aligned } = this.props;

        return (
            <SearchCustomContext>
                {({ customContext, query, setQuery, customAggregations }) => {
                    const { categories = [], metricsId } = customContext || {};
                    const catId = query.cat;

                    const selectedCategory =
                        useAlias && customAggregations ? customAggregations.category : findCat(catId, categories);
                    if (!selectedCategory || categories.length <= 1) {
                        return null;
                    }

                    if (isV2) {
                        return (
                            <Accordion
                                className={`accordion categories-wrapper${menuExpanded ? ' expanded' : ''}`}
                                expanded={this.state.menuExpanded}
                                onChange={this.toggleMenu}
                                size="small"
                            >
                                <AccordionTitle
                                    tooltipPosition={'right'}
                                    tooltip={
                                        description && {
                                            description: (
                                                <span dangerouslySetInnerHTML={Helpers.createMarkup(description)} />
                                            ),
                                        }
                                    }
                                    title="Categories"
                                />
                                <AccordionContent>
                                    {categories.map((category, i) => {
                                        let selected = selectedCategory._id == category._id ? 'selected' : '';
                                        return (
                                            <Checkbox
                                                key={category._id}
                                                checked={selected}
                                                onChange={this.changeCategory(category, setQuery)}
                                                aligned={aligned}
                                                gtmActions={gtmActions}
                                                disabled={!this.state.menuExpanded} // if accordion is not expanded, set disabled=true so it can't be focused.
                                            >
                                                {category.displayName}
                                            </Checkbox>
                                        );
                                    })}
                                </AccordionContent>
                            </Accordion>
                        );
                    }

                    return (
                        <div className={`categories-wrapper${menuExpanded ? ' expanded' : ''}`}>
                            <AccessibleElement
                                element="div"
                                ariaLabel="Categories Menu"
                                className="filters-heading"
                                onClick={this.toggleMenu}
                            >
                                <h2 tabIndex={0} aria-label="Categories">
                                    Categories
                                </h2>
                                {!isV2 && <span className="arrow"></span>}
                                {isV2 && <Icon name={menuExpanded ? 'minus_m' : 'plus'} size={20} />}
                            </AccessibleElement>
                            <div className="categories-main">
                                <div className="categories-menu">
                                    <div className="current-cat-wrapper">
                                        <span className="current-cat">{selectedCategory.displayName}</span>
                                    </div>
                                    <ul className="categories-list">
                                        {categories.map((category, i) => {
                                            let subCat = ~category.location.indexOf('<<') ? 'sub-category ' : '';
                                            let selected = selectedCategory._id == category._id ? 'selected' : '';
                                            return (
                                                <AccessibleElement
                                                    element="li"
                                                    key={i}
                                                    className={subCat + selected}
                                                    metricsData={{
                                                        'data-metrics-event-name': 'searchFilter',
                                                        'data-metrics-event': 'e_facet',
                                                        'data-metrics-filter-checked': true,
                                                        'data-metrics-filter-categories': `${
                                                            metricsId ? metricsId + ':' : ''
                                                        }category`,
                                                        'data-metrics-filter-value': category.displayName,
                                                    }}
                                                    aria-label={category.displayName}
                                                    tabIndex={menuExpanded ? 0 : -1}
                                                    onClick={this.changeCategory(category, setQuery)}
                                                >
                                                    {category.displayName}
                                                </AccessibleElement>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="clearfix padding-vert"></div>
                        </div>
                    );
                }}
            </SearchCustomContext>
        );
    }
}
