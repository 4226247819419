import React, { useState, useCallback } from 'react';
import { Counter, Button } from '@hpstellar/core';

import { SearchContext } from './search-context';
import SearchClearFilter from './search-clear-filter';
import Drawer from '../../page/components/drawer';

import '../css/search-drawer.less';

function SearchDrawer({ children, label, className, watchFields, position, resultCount, buttonProps, onClearFilter }) {
    const [isOpen, closeDrawer] = useState(false);
    const handleDrawerClose = useCallback(() => {
        setTimeout(() => {
            closeDrawer(!isOpen);
        }, 0);
    }, [isOpen]);

    let clearFunction = typeof onClearFilter === 'function' ? onClearFilter : handleDrawerClose;
    return (
        <>
            <SearchContext.Consumer>
                {({ filterCount }) => {
                    return (
                        <div className={'search-drawer-button-container'}>
                            <Button
                                className="search-drawer-button"
                                variation="secondary"
                                color="dark"
                                onClick={handleDrawerClose}
                                {...(buttonProps || {})}
                            >
                                {label}
                                {filterCount > 0 && ` (${filterCount})`}
                            </Button>
                            <Counter className="counter" count={resultCount} size="large" subsetDelimiter="of">
                                {`Result${resultCount > 1 ? 's' : ''}`}
                            </Counter>
                        </div>
                    );
                }}
            </SearchContext.Consumer>
            <Drawer open={isOpen} position={position} className={`search-drawer${className ? ' ' + className : ''}`}>
                <div className="search-drawer-content">
                    {/* TODO: could put default filter menu here if none provided*/}
                    {children({ closeDrawer: handleDrawerClose })}
                    <SearchContext.Consumer>
                        {({ hits }) => {
                            const displayCount = typeof resultCount !== 'undefined' ? resultCount : hits;
                            return (
                                <div className={'footer'}>
                                    {/*TODO: taking a short cut here with the buton classNames. We can update with proper Button component after the test*/}
                                    <SearchClearFilter
                                        onClick={clearFunction}
                                        watchFields={watchFields}
                                        className={'button-animated secondary primary large'}
                                    />
                                    <Button
                                        className={'see-results'}
                                        variation="primary"
                                        onClick={handleDrawerClose}
                                        {...(buttonProps || {})}
                                    >
                                        {`SHOW ${displayCount} RESULT${displayCount > 1 ? 'S' : ''}`}
                                    </Button>
                                </div>
                            );
                        }}
                    </SearchContext.Consumer>
                </div>
            </Drawer>
        </>
    );
}

SearchDrawer.defaultProps = {
    label: 'Filter',
    position: 'left',
};

export default SearchDrawer;
