import React from 'react';
import { MetricsElement } from 'react-metrics';

import FacetCheckbox from '../../search-filter/components/facet-checkbox';
import ProductRating from '../../product/components/product-rating';
import { memoizedFormatMetricValue } from '../../metrics/metrics-helpers';

export default ({
    filterName,
    field,
    options,
    onClick = () => {},
    metricsId,
    onFilterClick,
    useAlias = false,
    isV2,
    align,
    isFilterSelected,
}) => {
    if (options) {
        return (
            <React.Fragment>
                {options.map((opt, i) => {
                    if (opt) {
                        let { displayName, selected, count, _id, pgKey, type, value = {}, alias, canonical } = opt;
                        let { text } = value;
                        let queryValue = {
                            [`facets.${field}`]: useAlias ? alias : _id,
                        };
                        let filterSelected =
                            typeof isFilterSelected === 'function' ? isFilterSelected(opt, field) : selected;

                        const element = React.createElement(
                            isV2 ? 'div' : 'button',
                            {
                                'data-gtm-category': 'facet',
                                'data-gtm-id': memoizedFormatMetricValue(field),
                                'data-gtm-value': memoizedFormatMetricValue(displayName),
                                'data-gtm-checked': !filterSelected,
                                className: `facet-value-detail unstyled ${count == 0 ? ' disabled' : ''}`,
                                'aria-label': displayName,
                                tabIndex: isV2 ? -1 : 0,
                                onClick: () => {
                                    if (count === 0) {
                                        return;
                                    }
                                    setTimeout(() => {
                                        onClick(filterSelected ? 'remove' : 'add', queryValue);
                                        onFilterClick && onFilterClick(queryValue);
                                    }, 0);
                                },
                            },
                            <FacetCheckbox
                                type={type}
                                selected={filterSelected}
                                displayName={displayName}
                                count={count}
                                value={text}
                                isV2={isV2}
                                align={align}
                                canonical={canonical}
                            />,
                        );

                        if (isV2) {
                            return element;
                        }
                        return (
                            <li className={`facet-value facet-${type}`} key={i}>
                                {element}
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export const CustomRatingOptions = ({
    filterName,
    field,
    options,
    onClick = () => {},
    metricsId,
    onFilterClick,
    useAlias = false,
    isV2,
    align,
}) => {
    if (options) {
        return (
            <React.Fragment>
                {options.map((opt, i) => {
                    if (opt) {
                        let { displayName, selected, count, _id, type, value = {}, alias } = opt;
                        let { text } = value;
                        let queryValue = {
                            [`${field}`]: useAlias ? alias : _id,
                        };
                        let rating = text * 1;

                        return (
                            <li className={`facet-value facet-${type}`} key={i}>
                                {React.createElement(
                                    isV2 ? 'div' : 'button',
                                    {
                                        'data-gtm-category': 'facet',
                                        'data-gtm-id': memoizedFormatMetricValue(field),
                                        'data-gtm-value': memoizedFormatMetricValue(displayName),
                                        'data-gtm-checked': !selected,
                                        className: `facet-value-detail unstyled ${count == 0 ? ' disabled' : ''}`,
                                        'aria-label': displayName,
                                        tabIndex: isV2 ? -1 : 0,
                                        onClick: () => {
                                            if (count === 0) {
                                                return;
                                            }

                                            setTimeout(() => {
                                                onClick(selected ? 'remove' : 'replace', queryValue);
                                                onFilterClick && onFilterClick(queryValue);
                                            }, 0);
                                        },
                                    },
                                    <FacetCheckbox
                                        type={type}
                                        selected={selected}
                                        count={count}
                                        value={text}
                                        isV2={isV2}
                                        align={align}
                                    >
                                        <ProductRating rating={rating} />
                                        {rating < 5 && rating > 0 && ' & Up'}
                                    </FacetCheckbox>,
                                )}
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
            </React.Fragment>
        );
    } else {
        return null;
    }
};
